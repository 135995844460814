<template>
  <div class="talentsData">
    <div class="bottomCard ly-container__bg">
      <div class="ly_operation" style="margin-bottom: 10px">
        <div class="left">
          <!-- 添加案例 -->
          <el-button @click="addItem" type="primary" icon="el-icon-plus">添加用户</el-button>
        </div>
      </div>
      <!-- 表格数据 -->
      <el-table
        class="ly_table"
        :data="dataData"
        :cell-style="{ 'text-align': 'center' }"
        :header-cell-style="{
          background: '#F5F6F7',
          color: '#363E4D',
          'text-align': 'center',
        }"
      >
        <el-table-column prop="id" label="序号" align="center" width="120">
          <template v-slot="scope">
            <span>{{(queryInfo.pageNum-1)*queryInfo.pageSize+(scope.$index + 1)}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adminPhone" label="手机号"></el-table-column>
        <el-table-column prop="adminName" label="真实姓名"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>
        <el-table-column prop="inLoginTime" label="最后登陆时间"></el-table-column>
        <el-table-column prop="finLoginip" label="最后登录IP"></el-table-column>
        <el-table-column prop="loginNum" label="登录次数"></el-table-column>
        <el-table-column prop="solutionTechnology" label="状态">
          <template v-slot="{ row }">
            <el-switch
              v-model="row.isActivation"
              active-color="#13ce66"
              inactive-color="#c6c6c6"
              :active-value="1"
              :inactive-value="0"
              @change="btnClick(row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <button-table
              @buttonClick="editTalent(scope.row)"
              content="编辑"
              icon="jr-iconjr-icon-edit"
            />
            <button-table
              @buttonClick="deleteTalent(scope.row.id)"
              content="删除"
              icon="jr-iconjr-icon-delect"
            />
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :current-page="queryInfo.pageNumber"
        :page-sizes="[3, 5, 10]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <el-dialog
      :title="addEditValue ? '编辑用户' : '添加用户'"
      :visible.sync="dialogVisible"
      width="30%"
      @close="handleClose"
      center
    >
      <el-form label-width="100px" :model="userData">
        <el-form-item label="手机号：">
          <el-input v-model="userData.adminPhone"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名：">
          <el-input v-model="userData.adminName"></el-input>
        </el-form-item>
        <el-form-item label="是否启用：">
          <el-switch
            active-color="#13ce66"
            inactive-color="#c6c6c6"
            :active-value="1"
            :inactive-value="0"
            active-text="启用"
            inactive-text="禁止"
            v-model="userData.isActivation"
          ></el-switch>
        </el-form-item>
        <el-form-item label="角色选择：">
          <el-select v-model="userData.roleid" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addEditUser">确 定</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script src="../../assets/font/iconfont.js"></script>
<script>
import ButtonTable from '../../components/ButtonTable.vue'
import { getUser, addEditUsers, deleteUser, getRole } from '@/api/system.js'
export default {
  components: {
    ButtonTable,
  },
  created() {
    this.getSchemes()
    this.getRoles()
  },
  data() {
    return {
      addEditValue: false,
      queryInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      options: [],
      // 表格数据
      dataData: [],
      // 用户数据
      userData: {
        roleName: '',
        isActivation: '',
        adminPhone: '',
        adminName: '',
        roleid: null,
      },
      total: 0,
      // 删除
      deleteTalentData: [],
      // 弹出框
      dialogVisible: false,
    }
  },
  computed: {
    Authorization() {
      return sessionStorage.getItem('token')
    },
  },
  methods: {
    async getSchemes() {
      const res = await getUser(this.queryInfo)
      if (res.code === 200) {
        this.dataData = res.data.list
        this.total = res.data.total
      }
    },
    // 分页
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.getSchemes()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage
      this.getSchemes()
    },
    // 添加用户
    addItem() {
      this.dialogVisible = true
      this.addEditValue = false
    },
    async addEditUser() {
      Object.assign(this.userData, {
        Authorization: this.Authorization,
      })
      if (
        this.userData.adminName == '' ||
        this.userData.adminPhone == '' ||
        this.userData.roleid == null
      ) {
        this.$message.error('请完整信息！')
      } else {
        const res = await addEditUsers(this.userData)
        if (res.code == 200) {
          if (this.addEditValue) {
            this.$message.success('编辑成功')
          } else {
            this.$message.success('添加成功')
          }
          this.dialogVisible = false
          this.getSchemes()
          this.addEditValue = true
        } else {
          this.$message.error('添加失败')
        }
      }
    },
    // 跳转编辑页面
    editTalent(data) {
      this.dialogVisible = true
      this.addEditValue = true
      this.userData = data
    },
    // 单个删除
    deleteTalent(deleteID) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示').then(
        async () => {
          const res = await deleteUser({ id: deleteID })
          if (res.code == 200) {
            this.getSchemes()
            this.$message.success('删除成功')
          }
        }
      )
    },
    handleClose() {
      this.dialogVisible = false
      this.userData = {
        roleid: null,
        isActivation: null,
        adminPhone: '',
        adminName: '',
      }
    },
    formatString(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },
    async btnClick(data) {
      const res = await addEditUsers(data)
      this.$message.success('修改成功')
      this.getSchemes()
    },
    async getRoles() {
      const res = await getRole()
      this.options = res.data.map((item) => {
        return {
          label: item.cname,
          value: item.id,
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-card__body {
  padding: 10px;
}
// 下半el-card部分
.left {
  display: flex;
  align-items: center;
}
/deep/ .el-input-group__append {
  background: #1e9fff;
  color: #fff;
  border: 1px solid #1e9fff;
}
.el-dropdown-menu {
  text-align: center;
  border: 1px solid #dbdbdb;
  width: 176px;
  height: 74px;
}
/deep/ .upload {
  padding: 12px 20px;
}
/deep/ .el-dialog__header {
  text-align: center;
  font-weight: 700;
}
</style>
